<template>
  <NuxtPwaManifest />

  <v-app>
    <v-layout>
      <v-navigation-drawer
        v-model="leftDrawerOpen"
        color="grey-darken-3"
        :rail="miniDrawer && $vuetify.display.smAndUp"
        :permanent="miniDrawer && $vuetify.display.smAndUp"
        name="nav-drawer"
        rail-width="68"
      >
        <template #default><admin-layout-side-menu :rail="miniDrawer && $vuetify.display.smAndUp" /></template>
      </v-navigation-drawer>

      <v-app-bar>
        <v-app-bar-nav-icon icon="menu" @click="() => leftDrawerOpen = !leftDrawerOpen"></v-app-bar-nav-icon>

        <v-toolbar-title v-if="breadcrumbs.length && $vuetify.display.mdAndUp">
          <v-breadcrumbs
            class="px-0 navigation-breadcrumbs" :items="breadcrumbs.map((breadcrumb, index, self) => ({
              ...breadcrumb,
              title: (self.length - 1) === index ? title : $i18n.t(breadcrumb.title),
              disabled: (self.length - 1) === index
            }))"
          />
        </v-toolbar-title>
        <v-toolbar-title v-else>{{ title }}</v-toolbar-title>

        <template #append>
          <v-menu>
            <template #activator="{ props }">
              <v-btn icon="add" v-bind="props"></v-btn>
            </template>

            <template #default>
              <admin-add-menu />
            </template>
          </v-menu>

          <v-menu :close-on-content-click="false">
            <template #activator="{ props }">
              <v-btn icon="account_circle" v-bind="props">
              </v-btn>
            </template>

            <template #default="{ isActive }">
              <header-menu @close="() => isActive.value = false" />
            </template>
          </v-menu>
        </template>

        <template v-if="tabs.length > 0" #extension>
          <v-tabs :model-value="$route.query.tab" :class="$vuetify.display.mdAndUp ? 'px-4' : ''" @update:model-value="(tab) => updateTab(tab as string)">
            <v-tab
              v-for="tab in tabs"
              :key="tab.value"
              :value="tab.value"
              :prepend-icon="(tab.icon && $te(tab.icon)) ? $t(tab.icon) : tab.icon"
              class="text-none"
            >
              {{ $te(tab.label) ? $t(tab.label) : tab.label }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>

      <v-main scrollable name="main-content">
        <inner-loading :showing="pending"></inner-loading>

        <slot v-if="!pending" />

        <snackbar-toaster :snackbars="snackbars" />
      </v-main>
    </v-layout>
  </v-app>
</template>

<script setup lang="ts">
import { useTheme, useDisplay } from "vuetify"
import { jwtDecode } from "jwt-decode"

const display = useDisplay()
const $route = useRoute()
const $i18n = useI18n()

const userStore = useUserStore()
const authStore = useAuthStore()
const mainStore = useMainStore()

const { organizations, token } = storeToRefs(authStore)
const { snackbars } = storeToRefs(mainStore)

const { themes, global: globalTheme } = useTheme()
const { theme, miniDrawer } = useUserPreferences()

const leftDrawerOpen = ref(display.lgAndUp.value)

const { pending } = useAsyncData(async () => {
  const { _id } = await userStore.getCurrentUser()
  await authStore.fetchScopes({user: _id, object: organizations.value[0]})
}, { immediate: true })

const { computedTitle: title } = useRouteTitle()

const breadcrumbs = computed<Array<BreadcrumbItem>>(() => $route.meta.breadcrumbs && Array.isArray($route.meta.breadcrumbs) ? $route.meta.breadcrumbs : [])

const { tabs, updateTab } = useTabs()

watch(() => $route.meta.tabs, (newTabs) => {
  tabs.value = (Array.isArray(newTabs) && newTabs) || []
}, { immediate: true, deep: true })

watch(theme, () => {
  if (!theme.value) {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches
    const decodedToken = !prefersDark && token.value ? jwtDecode<TokenBody>(token.value) : undefined

    globalTheme.name.value = prefersDark ? 'dark' : decodedToken?.domain === DomainType.CIVIL ? 'civilLight' : 'light'
  }
  if (theme.value && themes.value[theme.value]) {
    globalTheme.name.value = theme.value
  }
}, { immediate: true })

</script>

<style>
.navigation-breadcrumbs .v-breadcrumbs-item--disabled {
  opacity: inherit;
}
</style>
